import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { AuctionDetailType, AuctionProps, AuctionsType } from '.';

import { URLS } from '../../api';
import { axiosBaseQuery } from '../../helpers';

export const auctionsAPI = createApi({
  reducerPath: 'auctionAPI',
  tagTypes: ['Auction'],
  baseQuery: axiosBaseQuery,
  endpoints: (build) => ({
    createAuction: build.mutation({
      query: ({ formData }) => ({
        url: URLS.auctions,
        method: 'post',
        data: formData,
        headers: { ContentType: 'multipart/form-data' },
      }),
      invalidatesTags: () => ['Auction'],
    }),
    deleteAuction: build.mutation({
      query: ({ id }) => ({
        url: `${URLS.auctions}/${id}`,
        method: 'delete',
      }),
      invalidatesTags: () => ['Auction'],
    }),
    editAuction: build.mutation({
      query: ({ formData, id }) => ({
        url: `${URLS.auctions}/${id}`,
        method: 'put',
        data: formData,
        headers: { ContentType: 'multipart/form-data' },
      }),
      invalidatesTags: () => ['Auction'],
    }),
    getAuction: build.query<AuctionsType, AuctionProps>({
      query: ({ page, perPage, url }) => ({
        url: !url
          ? `${URLS.auctions}?page_number=${page}&page_size=${perPage}`
          : `${URLS.auctions}?page_number=${page}&page_size=${perPage}&${url}`,
        method: 'get',
      }),
      providesTags: () => ['Auction'],
    }),
    getAuctionById: build.query<AuctionDetailType, { id: string }>({
      query: ({ id }) => ({
        url: `${URLS.auctions}/${id}`,
        method: 'get',
      }),
      providesTags: () => ['Auction'],
    }),
  }),
});

export const {
  useCreateAuctionMutation,
  useGetAuctionQuery,
  useDeleteAuctionMutation,
  useEditAuctionMutation,
  useGetAuctionByIdQuery,
} = auctionsAPI;
