import { ToastOptions } from 'react-toastify';
import { ISelectOption } from '../components/FormItems/InfineteSelect/type';

export const setingsNotification: ToastOptions = {
  position: 'bottom-center',
  autoClose: 3000,
  hideProgressBar: true,
  closeOnClick: false,
  pauseOnHover: false,
  draggable: true,
  progress: undefined,
  theme: 'colored',
};

export const regexLink =
  // eslint-disable-next-line no-useless-escape
  /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/gm;

export const itemsPerPage: ISelectOption[] = [
  { label: 10, value: 10 },
  { label: 30, value: 30 },
  { label: 50, value: 50 },
];

export const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const shortMonthNames = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'June',
  'Jul',
  'Aug',
  'Sept',
  'Oct',
  'Nov',
  'Dec',
];

export const sortsInFilterinPage = [
  {
    value: 'New to Old',
    text: 'New to Old',
    label: 'New to Old',
    sortDirection: 'desc',
    sortField: 'release_datetime_',
  },
  {
    value: 'Old to New',
    text: 'Old to New',
    label: 'Old to New',
    sortDirection: 'asc',
    sortField: 'release_datetime_',
  },
  {
    value: 'Low to high price',
    text: 'Low to high price',
    label: 'Low to high price',
    sortDirection: 'asc',
    sortField: 'price_',
  },
  {
    value: 'High to low price',
    text: 'High to low price',
    label: 'High to low price',
    sortDirection: 'desc',
    sortField: 'price_',
  },
  {
    value: 'Low to high circulation',
    text: 'Low to high circulation',
    label: 'Low to high circulation',
    sortDirection: 'asc',
    sortField: 'quantity_',
  },
  {
    value: 'High to low circulation',
    text: 'High to low circulation',
    label: 'High to low circulation',
    sortDirection: 'desc',
    sortField: 'quantity_',
  },
  {
    value: 'Low to high rarity',
    text: 'Low to high rarity',
    label: 'Low to high rarity',
    sortDirection: 'asc',
    sortField: 'rarity_',
  },
  {
    value: 'High to low rarity',
    text: 'High to low rarity',
    label: 'High to low rarity',
    sortDirection: 'desc',
    sortField: 'rarity_',
  },
];

export const rangePrice = [
  {
    label: 'Under $10',
    value: '1',
    id: 1,
    max: 10,
  },
  {
    label: '$10 - $50',
    value: '2',
    id: 2,
    min: 10,
    max: 50,
  },
  {
    label: '$50 - $100',
    value: '3',
    id: 3,
    min: 50,
    max: 100,
  },
  {
    label: '$100 - $200',
    value: '4',
    id: 4,
    min: 100,
    max: 200,
  },
  {
    label: '$200 - $500',
    value: '5',
    id: 5,
    min: 200,
    max: 500,
  },
  {
    label: '$500 - $1000',
    value: '6',
    id: 6,
    min: 500,
    max: 1000,
  },
  {
    label: '$1000 and over',
    value: '7',
    id: 7,
    min: 1000,
  },
];

export const itemTypes = [
  { label: 'Cards', value: 'Cards' },
  { label: 'Stickers', value: 'Stickers' },
  { label: 'Figures', value: 'Figures' },
  { label: 'Other', value: 'Other' },
];

export const itemCurrency = [
  { label: 'USDC', value: 'USDC' },
  { label: 'NTT', value: 'XNL' },
];

export const cuurencyWithPercent = itemCurrency.concat({ label: '%', value: '%' });
export const selectCurrency = [{ label: 'All', value: 'all' }]
  .concat(itemCurrency)
  .concat({ label: '%', value: 'percent' });

export const themeOptions = [
  { value: 'Cartoons', label: 'Cartoons' },
  { value: 'Movies', label: 'Movies' },
  { value: 'Games', label: 'Games' },
  { value: 'Music', label: 'Music' },
  { value: 'Pop Culture', label: 'Pop Culture' },
  { value: 'Other', label: 'Other' },
];

export const rarityOptions = [
  { value: 'Common', label: 'Common' },
  { value: 'Rare', label: 'Rare' },
  { value: 'Epic', label: 'Epic' },
  { value: 'Legendary', label: 'Legendary' },
  { value: 'Immortal', label: 'Immortal' },
];

export const onfidoStatusOptions = [
  { value: 'clear', label: 'Completed' },
  { value: 'pending', label: 'Pending' },
  { value: 'restricted', label: 'Restricted' },
  { value: 'consider', label: 'Consider' },
];

export const circleStatusOptions = [
  { value: 'clear', label: 'Completed' },
  { value: 'restricted', label: 'Restricted' },
];

export const amenitiesOptions = [
  { value: 'Wireless Internet', label: 'Wireless Internet' },
  { value: 'EV Charging', label: 'EV Charging' },
  { value: 'BBQ Grill', label: 'BBQ Grill' },
  { value: 'Ping Pong Table', label: 'Ping Pong Table' },
  { value: 'Jacuzzi', label: 'Jacuzzi' },
  { value: 'Central A/C', label: 'Central A/C' },
  { value: 'Smart TV', label: 'Smart TV' },
  { value: 'Swimming Pool', label: 'Swimming Pool' },
];

export const propertyStatuses = [
  { value: 'new', label: 'New' },
  { value: 'secondary', label: 'Secondary' },
];

export const rarityById = (id: string) => rarityOptions.find((el) => el.value === id);
export const themeById = (id: string) => themeOptions.find((el) => el.value === id);
export const typeById = (id: string) => itemTypes.find((el) => el.value === id);
export const currencyByKey = (currency: string) =>
  cuurencyWithPercent.find((el) => el.value === currency);
