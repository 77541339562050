import { ResourcesConfig } from 'aws-amplify';

const awsConfig: ResourcesConfig = {
  Auth: {
    Cognito: {
      userPoolClientId: process.env.REACT_APP_AWS_USER_POOL_CLIENT_ID!,
      userPoolId: process.env.REACT_APP_AWS_USER_POOL_IDd!,
      loginWith: {
        oauth: {
          domain: process.env.REACT_APP_AWS_COGNITO_DOMAIN!,
          scopes: ['openid', 'email', 'profile', 'phone'],
          redirectSignIn: ['http://localhost:8080', 'https://liqmanadmin.shiftmarkets.xyz'],
          redirectSignOut: ['http://localhost:8080/logout', 'https://liqmanadmin.shiftmarkets.xyz'],
          responseType: 'code',
        },
      },
    },
  },
};
export default awsConfig;
