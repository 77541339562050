import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { MysteryBoxProps, MysteryBoxesType, MysteryBoxItemsProps, MysteryBoxesItemsType } from '.';
import { URLS } from '../../api';
import { axiosBaseQuery } from '../../helpers';
import { ItemProps, ItemsType } from '../items';

export const mysteryBoxAPI = createApi({
  reducerPath: 'mysteryBoxAPI',
  tagTypes: ['MysteryBox', 'MysteryBoxItems'],
  baseQuery: axiosBaseQuery,
  endpoints: (build) => ({
    createMysteryBox: build.mutation({
      query: ({ formData, mysteryBoxType }) => ({
        url: `${URLS.mysteryBoxes}/${mysteryBoxType}`,
        method: 'post',
        data: formData,
        headers: { ContentType: 'multipart/form-data' },
        timeout: 0,
      }),
      invalidatesTags: () => ['MysteryBox'],
    }),
    editMysteryBox: build.mutation({
      query: ({ formData, mysteryBoxType, id }) => ({
        url: `${URLS.mysteryBoxes}/${mysteryBoxType}/${id}`,
        method: 'put',
        data: formData,
        headers: { ContentType: 'multipart/form-data' },
      }),
      invalidatesTags: () => ['MysteryBox'],
    }),
    deleteMysteryBox: build.mutation({
      query: ({ id }) => ({
        url: `${URLS.mysteryBoxes}/${id}`,
        method: 'delete',
      }),
      invalidatesTags: () => ['MysteryBox'],
    }),
    getMysteryBox: build.query<MysteryBoxesType, MysteryBoxProps>({
      query: ({ page, perPage, url }) => ({
        url: `${URLS.mysteryBoxes}?page_number=${page}&page_size=${perPage}&${url}`,
        method: 'get',
      }),
      providesTags: () => ['MysteryBox'],
    }),
    getMysteryBoxItems: build.query<MysteryBoxesItemsType, MysteryBoxItemsProps>({
      query: ({ page, perPage, url, id }) => ({
        url: `${URLS.mysteryBoxes}/${id}/items?page_number=${page}&page_size=${perPage}&${url}`,
        method: 'get',
      }),
      providesTags: () => ['MysteryBoxItems'],
    }),
    getItemsForMystery: build.query<ItemsType, ItemProps>({
      query: ({ page, perPage, url }) => ({
        url: `${
          URLS.items
        }?page_number=${page}&page_size=${perPage}&is_visible=true&purpose = store,not_set&is_sold_out=false${
          url ? `&${url}` : ''
        }`,
        method: 'get',
      }),
    }),
  }),
});

export const {
  useGetMysteryBoxQuery,
  useGetMysteryBoxItemsQuery,
  useDeleteMysteryBoxMutation,
  useCreateMysteryBoxMutation,
  useEditMysteryBoxMutation,
  useGetItemsForMysteryQuery,
} = mysteryBoxAPI;
